import { Button, Divider, Grid } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import UploadFileIcon from '@mui/icons-material/UploadFile';

type ToolbarProps = {
  onUploadClick?: () => void,
};


export default function GridListToolbar({
  onUploadClick = () => { },
}: ToolbarProps) {
  return (
    <GridToolbarContainer>
    <Grid container
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ p: 2 }}>
    <Grid item >
    <Button variant='contained' onClick={() => onUploadClick()}>
    <UploadFileIcon sx={{ marginRight: 1 }} />
    Upload
    </Button>
    </Grid>
    </Grid>
    </GridToolbarContainer>
  );
}