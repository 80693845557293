import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import assert from 'assert';
import {typeCheck} from 'type-check';
import ApiService, {ProductDatum} from '../../services/ApiService';

export interface CreationsSliceState {
  isGettingCreations: boolean,
  isGettingCreation: boolean,
  isCreatingProduct: boolean,
  isUpdatingProduct: boolean,
  creations: ProductDatum[],
  creation: ProductDatum|null,
}

const initialState: CreationsSliceState = {
  isGettingCreations: false,
  isGettingCreation: false,
  isCreatingProduct: false,
  isUpdatingProduct: false,
  creations: Array<ProductDatum>(),
  creation: null,
};


export const getAllCreations = createAsyncThunk(
  'creations/getAllCreations', async (idToken: string) => {
    assert(typeCheck('String', idToken), 'idToken must be string');
    const result = await ApiService.getInstance()
      .getAllCreations(idToken);
    return result;
});

export const getCreationById = createAsyncThunk(
  'creations/getCreationById', async (params: {
    idToken: string, productId: string,
  }) => {
    const {
      idToken, productId,
    } = params;
    assert(typeCheck('String', idToken), 'idToken must be string');
    assert(typeCheck('String', productId), 'productId must be string');
    const result = await ApiService.getInstance()
      .getProductById(idToken, productId);
    return result;
});

export const createProduct = createAsyncThunk(
  'creations/createProduct', async (params: {
    idToken: string,
    productDetails: ProductDatum,
  }) => {
    const { idToken, productDetails } = params;
    assert(typeCheck('String', idToken), 'idToken must be string');
    const result = await ApiService.getInstance()
      .createProduct(idToken, productDetails);
    return result;
});

export const editProduct = createAsyncThunk(
  'creations/editProduct', async (params: {
    idToken: string,
    productDetails: ProductDatum,
  }) => {
    const { idToken, productDetails } = params;
    assert(typeCheck('String', idToken), 'idToken must be string');
    const result = await ApiService.getInstance()
      .editProduct(idToken, productDetails);
    return result;
});

export const creationsSlice = createSlice({
  name: 'creations',
  initialState,
  reducers: (create) => ({
    clearEditing: create.reducer((state) => {
      state.isCreatingProduct = false;
      state.isUpdatingProduct = false;
      state.creation = null;
    }),
  }),
  extraReducers: (builder) => {
    // ------------ Get Creations ---------------
    builder.addCase(getAllCreations.pending, (state, action) => {
      state.isGettingCreations = true;
    }).addCase(getAllCreations.fulfilled, (state, action) => {
      state.isGettingCreations = false;
      state.creations = action.payload;
    }).addCase(getAllCreations.rejected, (state, action) => {
      state.isGettingCreations = false;
    });

    // ------------ Get Product By ID ---------------
    builder.addCase(getCreationById.pending, (state, action) => {
      state.isGettingCreation = true;
    }).addCase(getCreationById.fulfilled, (state, action) => {
      state.isGettingCreation = false;
      state.creation = action.payload;
    }).addCase(getCreationById.rejected, (state, action) => {
      state.isGettingCreation = false;
      state.creation = null;
    });

    // ------------ Create Product ---------------
    builder.addCase(createProduct.pending, (state, action) => {
      state.isCreatingProduct = true;
    }).addCase(createProduct.fulfilled, (state, action) => {
      state.isCreatingProduct = false;
    }).addCase(createProduct.rejected, (state, action) => {
      state.isCreatingProduct = false;
    });

    // ------------ Edit Product ---------------
    builder.addCase(editProduct.pending, (state, action) => {
      state.isUpdatingProduct = true;
    }).addCase(editProduct.fulfilled, (state, action) => {
      state.isUpdatingProduct = false;
      state.creation = null;
    }).addCase(editProduct.rejected, (state, action) => {
      state.isUpdatingProduct = false;
    });
  },
  selectors: {
    selectCreations: (state) => state.creations,
    selectCreation: (state) => state.creation,
    selectIsGettingCreations: (state) => state.isGettingCreations,
    selectIsGettingCreation: (state) => state.isGettingCreation,
    selectIsCreatingProduct: (state) => state.isCreatingProduct,
    selectIsUpdatingProduct: (state) => state.isUpdatingProduct,
  },
});

export const {
  selectCreations,
  selectCreation,
  selectIsGettingCreations,
  selectIsGettingCreation,
  selectIsUpdatingProduct,
  selectIsCreatingProduct,
} = creationsSlice.selectors;

export const {
  clearEditing,
} = creationsSlice.actions;

export default creationsSlice.reducer;