import { LinearProgress } from '@mui/material';
import { useRef, useEffect, useState, useCallback } from 'react';
import videojs from 'video.js';
import 'videojs-hls-quality-selector';
import contribQualityLevels from 'videojs-contrib-quality-levels';
import { useWindowSize } from "@uidotdev/usehooks";
import 'video.js/dist/video-js.css';
import { debounce } from 'lodash';

type VideoJsInstance = ReturnType<typeof videojs>;

type VideoJsProps = {
  options: any,
  onReady?: (videoJs: VideoJsInstance) => void,
};

function VideoJS(props: VideoJsProps) {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<VideoJsInstance | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { options, onReady } = props;

  useEffect(() => {
    if (!playerRef.current) {
      setIsLoading(true);
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current?.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        (player as any).qualityLevels();
        (player as any).hlsQualitySelector({ displayCurrentQuality: true });
        onReady && onReady(player);
        setIsLoading(false);
      });

      player.on('loadeddata', () => {
        setIsLoading(false);
      });
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, onReady]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      {isLoading && !videoRef.current && !playerRef.current && (
        <LinearProgress />
      )}
      <div ref={videoRef} />
    </div>
  );
}

type VideoJsSource = {
  src: string,
  type?: string,
}

type VideoPlayerProps = {
  autoplay?: boolean,
  controls?: boolean,
  responsive?: boolean,
  fluid?: boolean,
  sources?: VideoJsSource[],
  height?: number|string,
  width?: number | string,
  onReady?: (videoJs: VideoJsInstance) => void,
}

export default function VideoPlayer(props: VideoPlayerProps) {
  const windowSize = useWindowSize();

  const width = useRef<number | undefined>(undefined);
  const height = useRef<number | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [vjsPlayer, setVjsPlayer] = useState<VideoJsInstance | null>(null);

  const isTallVideo = height.current && width.current &&
    (height.current / width.current) > 0.75;
  const maxHeight = height.current &&
    height.current > window.innerHeight ?
    (window.innerHeight * 0.80) : height.current;

  const videoJsProps = {
    controls: true,
    playbackRates: [0.5, 1, 1.5, 2, 2.5, 3],
    width: isTallVideo ? 'auto' : width.current,
    height: isTallVideo ? maxHeight : 'auto',
    ...props,
  };

  const resize = useCallback(
    debounce(() => {
      const parentNode = containerRef.current;
      width.current = parentNode?.getBoundingClientRect().width;
      height.current = windowSize.height ? windowSize.height * 0.80 :
        parentNode?.getBoundingClientRect().height;

      if (vjsPlayer && !vjsPlayer.isDisposed() && vjsPlayer.videoWidth()) {
        setTimeout(() => {
          vjsPlayer.height(maxHeight ?? height.current);
          width.current && vjsPlayer.width(width.current);
        }, 100);
      }
    }, 300),
    [containerRef, isTallVideo, maxHeight, vjsPlayer, windowSize]
  );

  useEffect(() => {
    resize();
  }, [windowSize, resize]);

  return (
    <div ref={containerRef}>
      <VideoJS options={videoJsProps} onReady={(player) => {
        props.onReady && props.onReady(player);
        player.one('loadeddata', () => {
          setVjsPlayer(player);
          resize();
        });
      }} />
    </div>
  );
}
