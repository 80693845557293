import { styled } from '@mui/material';
import AppToolbar from './AppToolbar';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function Home() {
  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', 'dim');
  });
  return (
    <div className="App">
      <header className="App-header">
        <AppToolbar />
      </header>
      <Offset />
      <Outlet/>
    </div>
    );
  }

  export default Home;
