import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";
import Environment from "./services/environment";

let firebaseConfig: FirebaseOptions = {
  // DEV
  apiKey: "AIzaSyBxDI556Ju_aLdEZvJX8n5odVY75T-23uE",
  authDomain: "benetti-dev.firebaseapp.com",
  projectId: "benetti-dev",
  storageBucket: "benetti-dev.appspot.com",
  messagingSenderId: "475550811487",
  appId: "1:475550811487:web:e59561ad645eafa1c962ea",
  measurementId: "G-1DY0H0CRD5"
}
if (!Environment.getInstance().isDev()) {
  // PROD
  firebaseConfig = {
    apiKey: "AIzaSyD8gxgSnV0pmUP8biL-2keqIUa5tFoh6CE",
    authDomain: "benetti-417611.firebaseapp.com",
    databaseURL: "https://benetti-417611-default-rtdb.firebaseio.com",
    projectId: "benetti-417611",
    storageBucket: "benetti-417611.appspot.com",
    messagingSenderId: "424535713437",
    appId: "1:424535713437:web:b8e73a73d1bdad629c1674",
  };
}

const firebaseApp = initializeApp(firebaseConfig);
getPerformance(firebaseApp);
getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);

window.firebase = firebaseApp;
window.auth = auth;

export type FirebaseProviderDatum = {
  providerId: string,
  uid: string,
  displayName: string,
  email: string,
  phoneNumber: string,
  photoURL: string,
};

export type FirebaseUserSerialized = {
  photoURL: string,
  displayName: string,
  email: string,
  uid: string,
  emailVerified: boolean,
  isAnonymous: boolean,
  createdAt: string,
  lastLoginAt: string,
  providerData: FirebaseProviderDatum[],
  stsTokenManager: {
    refreshToken: string,
    accessToken: string,
    expirationTime: number,
  },
}

// Configure Providers
const FirebaseProviders = {
  Google: new GoogleAuthProvider(),
};

export {
  auth,
  firebaseApp,
  FirebaseProviders,
};