import { BasePlugin } from '@uppy/core'
import { Uppy, UppyOptions } from '@uppy/core';
import { getAuth } from 'firebase/auth';
import {
  UploadTaskSnapshot,
  getDownloadURL,
  getStorage,
  uploadBytesResumable,
  ref,
} from 'firebase/storage';

import { v4 as uuid } from 'uuid';

const storage = getStorage();
export default class FirebaseCloudStoragePlugin extends BasePlugin {

  private uploadPath: string = 'videos';

  constructor(uppy: Uppy, opts: UppyOptions) {
    super(uppy, opts);
    this.type = 'uploader';
    this.id = 'FirebaseCloudStoragePlugin';
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds: string[]): Promise<any>{
    const uppy = this.uppy;
    this.uppy.emit('upload-start', this.uppy.getFiles());
    return Promise.all(
      fileIds.map((id) => {
        return new Promise<void>((resolve, reject) => {
          const file = this.uppy.getFile(id);
          const refId = uuid();
          const userId = getAuth().currentUser?.uid;
          const folder = userId ? `${userId}/${this.uploadPath}/` : '';

          const path = `${folder}${refId}`;
          console.log(storage, folder, path);
          const storageRef = ref(storage, path);
          console.log(storageRef, path);
          const uploadTask = uploadBytesResumable(
            storageRef,
            file.data,
            {
              customMetadata: {
                fileName: file.name,
                userId: userId ?? '',
                size: String(file.size),
              }
            }
          );
          uppy.emit('upload-started', file);
          uppy.on('cancel-all', () => {
            uploadTask.cancel();
          });
          uploadTask.on(
            'state_changed',
            (snapshot: UploadTaskSnapshot) => {
              const bytesUploaded = snapshot.bytesTransferred;
              const bytesTotal = snapshot.totalBytes;
              console.log(`progress happening: ${bytesUploaded}/${bytesTotal}`);
              uppy.emit('upload-progress', uppy.getFile(file.id),  {
                uploader: this,
                bytesUploaded,
                bytesTotal,
              });
            },
            (error: Error) => {
              uppy.emit('upload-error', file, error);
              reject(error);
            },
            async () => {
              const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              const file = this.uppy.getFile(id);
              Object.assign(file, 'downloadUrl', downloadUrl);
              uppy.setFileState(file.id, { status: 'success' });
              console.log('Time to emit success', { file, downloadUrl });
              this.uppy.emit(
                'upload-success',
                file,
                uploadTask.snapshot,
                downloadUrl,
              );
              resolve();
            }
          );
        });
      })
    );
  }

  install() {
    this.uppy.addUploader(this.uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this.uploadFile);
  }
}