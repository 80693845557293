import Uppy, { debugLogger, UploadResult } from '@uppy/core';
import Webcam from '@uppy/webcam';
import { Dashboard } from '@uppy/react';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

import FirebaseCloudStoragePlugin from '../../plugins/FirebaseCloudStorageUppyPlugin';
import { Box, Button, Grid } from '@mui/material';
import { useMemo, DragEventHandler } from 'react';

// TODO(benedictchen): Change upload to upload directly to
// api.video
// @see https://docs.api.video/sdks/vod/apivideo-typescript-uploader?_gl=1*zw8jwt*_gcl_au*MTE3Mzg3OTcxMi4xNzE0NDU1MjUw*_ga*MTIxNzU2ODUzOS4xNzE0NDU1MjUx*_ga_PSYKRMEWL4*MTcxNDcxNzI0NS4xMC4xLjE3MTQ3MTczODAuMC4wLjA.
// @see https://api.video/blog/tutorials/delegated-uploads/

type UppyUploadResult =
UploadResult<Record<string, unknown>, Record<string, unknown>>;

export default function Uploader({
  onComplete = () => { },
  onCancelClick = () => {},
}: {
  onCancelClick: () => void,
  onComplete?: (result: UppyUploadResult) => void,
}) {
  const uppy = useMemo(() => {
    return new Uppy({
      logger: debugLogger,
    })
    .use(FirebaseCloudStoragePlugin as any)
    .use(Webcam)
    .on('complete', (result) => {
      console.log('>>>DONE', { result });
      onComplete(result);
    });
  }, []);

  uppy.setOptions({
    // autoProceed: false,
    // allowMultipleUploads: true,
    debug: true,
    restrictions: {
      // maxNumberOfFiles: 20,
      // minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
      allowedFileTypes: [
        'video/mp4',
        'video/mpd',
        'video/webm',
        'video/m3u8',
      ],
    },
    allowMultipleUploadBatches: false,
  });

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Grid container
    direction='column'
    justifyContent='center'
      alignItems='center'
    spacing={3}>
      <Grid item>
        <Button variant='outlined' onClick={() => {
          uppy.once('cancel-all', () => {
            onCancelClick();
          });
          uppy.cancelAll();
        }}>
          Cancel Upload
        </Button>
      </Grid>
    <Grid item>
    <Box
    sx={{
      position: 'relative',
      backgroundColor: 'red',
      minHeight: 300,
      borderRadius: 2
    }}
    onDrop={handleDrop}
    >
    <Dashboard uppy={uppy}
    hideCancelButton
    showLinkToFileUploadResult
    proudlyDisplayPoweredByUppy={false}
    showProgressDetails={true}
    theme={'auto'}
    />
    </Box>
    </Grid>
    </Grid>
  );
}
