import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Tab, Grid, debounce, Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect, useRef, useState } from 'react';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { auth, FirebaseProviders } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { GoogleLoginButton } from './login/googleLoginButton';
import { useAppDispatch, useAppSelector } from '../state/store';
import {
  loginWithCredentials, registerWithCredentials, setIdToken, userSlice,
} from '../state/slices/user';
import { useNavigate } from 'react-router';
import Paths from '../paths';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  borderRadius: 1,
  opacity: 0.95,
  // p: '0.5em 1em 1em 0.5em',
  p: 2,
};

type LoginModalProps = {
  open: boolean;
}

type LoginPanelProps = {
  onChange: Function,
}


function LoginPanel(props: LoginPanelProps) {
  const [showPassword, setShowPassword] = useState(false);
  const userInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const CHANGE_WAIT_MS = 10;

  const triggerChange = debounce(() => {
    props.onChange(
      userInputRef.current?.value,
      passwordInputRef.current?.value);
    }, CHANGE_WAIT_MS);

    return (
      <>
      <FormControl fullWidth sx={{ marginBottom: 1 }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-user">User</InputLabel>
      <OutlinedInput
      id="outlined-adornment-user"
      inputRef={userInputRef}
      type={'text'}
      onChange={(element) => {
        triggerChange();
      }}
      label="User"
      />
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
      <OutlinedInput
      id="outlined-adornment-password"
      type={showPassword ? 'text' : 'password'}
      inputRef={passwordInputRef}
      endAdornment={
        <InputAdornment position="end">
        <IconButton
        aria-label="toggle password visibility"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
        edge="end"
        >
        {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
        </InputAdornment>
      }
      onChange={(element) => {
        triggerChange();

      }}

      label="Password"
      />
      </FormControl>
      </>
    );
  }



  export default function LoginModal(props: LoginModalProps = { open: true }) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isLoggingIn = useAppSelector(userSlice.selectors.isLoggingIn);
    const isRegistering = useAppSelector(userSlice.selectors.isRegistering);
    const idToken = useAppSelector(userSlice.selectors.selectIdToken);

    const errors = useAppSelector(userSlice.selectors.selectErrors);


    console.warn({ errors });


    useEffect(() => {
      if (idToken) {
        navigate(Paths.ROOT);
      }
    });
    const [tabValue, setTabValue] = useState('1');
    const [userValue, setUserValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    return (
      <Modal
      open={props.open}
      onClose={() => { }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
      <Box sx={style} className={'opaque'}>
      <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <TabList onChange={(_, newValue) => setTabValue(newValue)} aria-label="lab API tabs example">
      <Tab label="Login" value="1" />
      <Tab label="Register" value="2" />
      </TabList>
      </Box>
      <Box sx={{ marginTop: 2 }}>
      {errors.map((error, index) => {
        return (
          <Alert key={index} severity="error" sx={{marginBottom: 1}}>
          {error}
          </Alert>
        );
      })}
      <LoginPanel onChange={(user: string, password: string) => {
        console.log('values change', {user, password})
        setUserValue(user);
        setPasswordValue(password);
      }} />
      </Box>
      <TabPanel value="1" sx={{ padding: 0 }}>
      <Box>
      <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      >
      <LoadingButton
      loading={isLoggingIn} variant="contained"
      disabled={isRegistering || isLoggingIn}
      sx={{ width: '100%'}}
      onClick={async () => {
        dispatch(loginWithCredentials({
          email: userValue, password: passwordValue,
        }));
      }}>
      Log In with Email
      </LoadingButton>
      </Grid>
      </Box>

      <Box sx={{
        borderTop: 1, marginTop: 2, paddingTop: 3, borderColor: 'divider',
        width: '100%',
      }}>

      <GoogleLoginButton sx={{ width: '100%' }}
      disabled={isRegistering || isLoggingIn}
      onClick={async () => {
        const googleProvider = FirebaseProviders.Google;
        googleProvider.setCustomParameters({ prompt: 'select_account' });
        const result = await signInWithPopup(auth, FirebaseProviders.Google);
        const idToken = await result.user.getIdToken();
        dispatch(setIdToken(idToken))
      }}/>

      </Box>
      </TabPanel>
      <TabPanel value="2">
      <Grid
      container direction="column">
      <LoadingButton loading={isLoggingIn} variant="contained"
      disabled={isRegistering || isLoggingIn}
      onClick={async () => {
        dispatch(registerWithCredentials({
          email: userValue, password: passwordValue,
        }));
      }}>
      Register
      </LoadingButton>
      </Grid>
      </TabPanel>
      </TabContext>
      </Box>
      </Modal>
    );
  }