import { useEffect } from "react";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState } from "lexical";


type Props = {
  onChange?: (value: EditorState) => void,
}

export default function LexicalOnChangePlugin({
  onChange = () => { }
}: Props) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({editorState}) => {
      onChange(editorState);
    });
  }, [editor, onChange]);
  return null;
}