import { AppBar, Container, Typography, Toolbar, IconButton, Box, Menu, MenuItem, Button, Tooltip, Avatar, Chip, Fab, useTheme } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import logo from '../logo-white.svg';
// import logoText from '../brainbloomer-text-white.svg';
import { useAppDispatch, useAppSelector } from '../state/store';
import { userSlice, logout } from '../state/slices/user';
import Environment from '../services/environment';
import { useNavigate } from 'react-router-dom';
import { BrainBloomerTextLogo } from './logo/BrainBloomerText';
import AddIcon from '@mui/icons-material/Add';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Paths from '../paths';

const pages = [
  { text: 'My Library', icon: <LibraryBooksIcon />, url: Paths.LIBRARY },
  { text: 'My Subscriptions', icon: <HowToRegIcon />, url: Paths.SUBSCRIPTIONS },
  { text: 'My Creations', icon: <SmartToyIcon />, url: Paths.CREATIONS },
];
const settings = [
  'Profile',
  // 'Account',
  // 'Dashboard',
  'Logout'
];

type ToolbarProps = {}

function AppToolbar(props: ToolbarProps) {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const idToken = useAppSelector(userSlice.selectors.selectIdToken);
  const userProfile = useAppSelector(userSlice.selectors.selectUser);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (url?: string) => {
    setAnchorElNav(null);
    url && navigate(url);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position='fixed'>
    <Container maxWidth='xl'>
    <Toolbar disableGutters>
    {Environment.getInstance().isDev() && (
      <Chip variant='filled' color='warning' label='dev'
      sx={{
        position: 'absolute',top: 15, opacity: 0.7,
      }} />
    )}
    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
    <Box sx={{
      display: { xs: 'none', md: 'flex' }, mr: 1, cursor: 'pointer',
      marginRight: 5,
    }} onClick={() => navigate(Paths.ROOT)}>
    <img src={logo} className='App-logo' alt='logo' />
    <div style={{
      height: 40, width: 100, marginLeft: 60,
    }}>
    <BrainBloomerTextLogo />
    </div>
    </Box>

    {idToken && (
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
      size='large'
      aria-label='account of current user'
      aria-controls='menu-appbar'
      aria-haspopup='true'
      onClick={handleOpenNavMenu}
      color='inherit'
      >
      <MenuIcon />
      </IconButton>

      <Menu
      id='menu-appbar'
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorElNav)}
      onClose={() => handleCloseNavMenu()}
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
      >
      {pages.map((page) => (
        <MenuItem key={page.text} onClick={() => handleCloseNavMenu(page.url)}>
        <Typography textAlign='center'>{page.icon} {page.text}</Typography>
        </MenuItem>
      ))}
      </Menu>

      </Box>
    )}


    <Box sx={{
      display: {
        xs: 'flex', md: 'none',
      }, mr: 1, position: 'relative',
      left: { xs: '-40px' },
      top: { xs: '-25px' },
      cursor: 'pointer',
    }}
    onClick={() => navigate(Paths.ROOT)}
    >
    <img  src={logo} className='App-logo' alt='logo' />
    </Box>
  <Box sx={{
    mr: 2,
    display: { xs: 'flex', md: 'none' },
    flexGrow: 1,
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  }}
  onClick={() => navigate(Paths.ROOT)}>
  <div style={{ height: 100, width: 100, position: 'absolute', left: 50, top: 13 }}>
  <BrainBloomerTextLogo />
  </div>
  </Box>

  <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
  {idToken && pages.map((page) => (
    <Button
    key={page.text}
    onClick={() => handleCloseNavMenu(page.url)}
    sx={{ my: 2, color: 'white', display: 'block' }}
    >
    { page.icon} {page.text}
    </Button>
  ))}
  </Box>


  {idToken && (<Box sx={{ flexGrow: 0 }}>
    <Tooltip title='Create new course'>
    <Fab color='primary' aria-label='add' sx={{
      height: 30, width: 35, marginRight: 3
    }}
    onClick={() => navigate(Paths.PRODUCT_CREATE)}>
    <AddIcon />
    </Fab>
    </Tooltip>

    <Tooltip title='Open settings'>
    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
    <Avatar alt={userProfile?.displayName ?? ''} src={userProfile?.photoURL ?? ''} />
    </IconButton>
    </Tooltip>
    <Menu
    sx={{ mt: '45px' }}
    id='menu-appbar'
    anchorEl={anchorElUser}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={Boolean(anchorElUser)}
    onClose={handleCloseUserMenu}
    >
    {settings.map((setting) => (
      <MenuItem key={setting} onClick={() => {
        if (setting === 'Logout') {
          dispatch(logout());
        } else if (setting === 'Profile') {
          navigate(Paths.PROFILE);
        }
        handleCloseUserMenu();
      }}>
      <Typography textAlign='center'>{setting}</Typography>
      </MenuItem>
    ))}
    </Menu>
    </Box>)}
    {!idToken && (
      <Button variant='contained' onClick={() => {
        navigate(Paths.LOGIN);
      }}>Login</Button>
    )}
    </Toolbar>
    </Container>
    </AppBar>
  );
}

export default AppToolbar;