enum Paths {
  ROOT = '/',
  PRODUCT_DETAILS_PUBLIC = '/product/:productId',
  LOGIN = '/login',
  LOGOUT = '/logout',
  HOME = '/home',
  PROFILE = '/home/profile',
  LIBRARY = '/home/library',
  SUBSCRIPTIONS = '/home/subscriptions',
  CREATIONS = '/home/creations',
  PRODUCT_CREATE = '/home/product/create',
  PRODUCT_EDIT = '/home/creations/:productId/edit',
  PRODUCT_LISTING_LOGGED_IN = '/home/product',
  PRODUCT_DETAILS = '/product/:productId',
  PRODUCT_CHECKOUT = '/home/checkout/:productId/:action',
  PRODUCT_CHECKOUT_COMPLETE = '/home/checkout/complete',
  SUBSCRIBE = '/home/subscribe/:userId',
};

export default Paths;