import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from "react";
import Paths from "../../paths";

const analytics = getAnalytics();

export default function CheckoutComplete() {

  useEffect(() => {
    logEvent(analytics, 'checkout_complete');
  });
  const navigate = useNavigate();
  return (
    <Box sx={{ textAlign: 'center', paddingTop: 30 }}>
      <Box sx={{ marginBottom: 3 }}>
        Thank you for buying our stuff.<br/>
        We will process your payment shortly.
      </Box>
      <Button variant="outlined" onClick={() => navigate(Paths.ROOT)}>
        Return to Home
      </Button>
    </Box>
  );
}