import { Button, CircularProgress, Paper, styled, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { userSlice, updateProfileForToken, getProfileForToken } from "../../state/slices/user";
import { useEffect, useState } from "react";

const FormTextField = styled(TextField)(( { theme }) => {
  return {
    marginBottom: 30,
  }
});


export default function UserProfile() {
  const idToken = useAppSelector(userSlice.selectors.selectIdToken);
  const user = useAppSelector(userSlice.selectors.selectUser);
  const isGettingProfile = useAppSelector(userSlice.selectors.isGettingProfile);
  const isUpdatingProfile = useAppSelector(userSlice.selectors.isUpdatingProfile);
  const dispatch = useAppDispatch();

  const [displayName, setDisplayName] = useState<string>('')

  useEffect(() => {
    if (idToken) {
      dispatch(getProfileForToken(idToken))
    }
  }, [idToken]);

  useEffect(() => {
    user && setDisplayName(user.displayName);
  }, [user]);

  if (!user || !idToken || isGettingProfile) {
    return <CircularProgress />;
  }
  return (
    <Paper
      elevation={5}
      sx={{ p: 10 }}
    >
      <FormTextField
        label="Email" variant="filled"
        value={user?.email}
        fullWidth
        disabled
      />
      <FormTextField
        label="Name" variant="filled"
        value={displayName}
        fullWidth
        disabled={isUpdatingProfile}
        onChange={(e) => setDisplayName(e.target.value)}
      />
      <Button
        disabled={isUpdatingProfile}
        variant="contained"
        onClick={() => dispatch(updateProfileForToken({
          idToken, fields: {
            displayName: displayName,
          },
        }))}>
        Save
      </Button>
    </Paper>
  );
}