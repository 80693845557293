import axios, { Axios, AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import Environment from './environment';
import { auth } from '../firebase';
import {typeCheck} from 'type-check';
import assert from 'assert';
import { ProductFeed } from '@server/other/classes';

const env = Environment.getInstance();
const API_HOST = env.getApiHost();

export enum ProductUnitTypes {
  VIDEO = 'video',
  ARTICLE = 'article',
};

export type FileUploadItem = {
  name: string,
  fileName: string,
  metadata: {
    size: string,
  },
  url: string,
}

export type ProductContent = {
  id?: string,
  title: string,
  type: ProductUnitTypes | null | undefined,
  value: string,
};

export type ProductContentGroup = {
  title: string,
  content: ProductContent[],
};

export type ProductDatum = {
  id?: string,
  title: string,
  imageUrl: string,
  isPublished: boolean,
  content: ProductContentGroup[],
  creatorEmail?: string,
  creator?: User,
  monthlyPrice?: number,
  coursePrice?: number,
  currency?: string,
}

export type User = {
  uid: string,
  displayName: string,
  email: string,
  expiresIn: string,
  idToken: string,
  kind: string,
  localId: string,
  profilePicture: string,
  refreshToken: string,
  registered: boolean,
  photoURL?: string,
};

export type UpdateProfileParams = {
  // email: TBD
  displayName: string,
};


class ApiService {

  private api: Axios;

  private failureListeners: Set<Function> = new Set([]);

  private static _instance: ApiService;

  private getBaseUrl() {
    return API_HOST;
  }

  private async getUserIdToken() {
    return await auth.currentUser?.getIdToken();
  }

  private aborter = new AbortController();

  private constructor() {
    this.api = axios.create({
      baseURL: this.getBaseUrl(),
      // timeout: 3000,
      // headers: {
      //   'X-Custom-Header': this.getUserIdToken();
      // }
      signal: this.aborter.signal,
    });
    axiosRetry(this.api as AxiosInstance, {
      retries: 5,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: async (error) => {
        console.log('shall we retry?');
        this.emitFailure(error);
        if (!(error && error.response)) {
          return false;
        }
        // Ignore aborts.
        if (error?.message && error?.message.indexOf('Abort') !== -1) {
          return false;
        }
        if (error?.response?.status === 404) {
          return false;
        }
        if (error?.response?.status > 400 && error?.response?.status < 500) {
          // TODO(benedictchen): Figure out how to fix this authentication issue
          // when token expires.
          return true;
        }
        // if retry condition is not specified, by default idempotent requests are retried
        return error.response.status === 503;
      },
    });
    // attach interceptors
    this.api.interceptors.response.use((response) => response,
    async (error) => {
      this.emitFailure(error);
      console.error('interceptor', { error });
      throw error;
    });
  }

  public static getInstance() {
    return this._instance || (this._instance = new this());
  }

  public abortAll() {
    this.aborter.abort();
  }

  public onFailure(callback: Function) {
    console.log('failure listeners', this.failureListeners);
    const currentListeners = Array.from(this.failureListeners)
    .filter((v) => !!v);

    const listenersAsStrings = currentListeners.map((fn) => fn?.toString());
    const listenerNames = currentListeners.map((fn) => fn.name);

    const uniqueFnStrings = new Set(listenersAsStrings);
    const uniqueFnNames = new Set(listenerNames.filter((v) => !!v));

    const newFnAsString = callback.toString && callback.toString();
    const newFnName = callback.name ?? null;

    if (!this.failureListeners.has(callback) &&
    !uniqueFnStrings.has(newFnAsString) &&
    !(newFnName && uniqueFnNames.has(newFnName))
  ) {
    this.failureListeners.add(callback);
  }
}

public removeFailureListener(callback: Function) {
  this.failureListeners.delete(callback);
}

private emitFailure(error?: Error) {
  if (error instanceof AxiosError) {
    if (error.response?.status === 404) {
      // Do not emit for "Not Found" errors.
      return;
    }
  }
  console.log('emitting failures', this.failureListeners)
  this.failureListeners.forEach((callback) => {
    callback && callback(error);
  });
  const filteredListeners = Array.from(this.failureListeners)
  .filter((v) => !!v);
  this.failureListeners = new Set(filteredListeners);
}

async loginWithCredentials(email: string, password: string) {
  const result = await this.api.post(`${API_HOST}/api/v1/account/login`, {
    email, password,
  }).catch((error) => {
    console.error({error})
    throw error?.response?.data || error;
  });
  const user = result.data.result;
  console.log('loginWithCredentials: the result is here:', { user });
  return user;
}

async registerWithCredentials(email: string, password: string): Promise<User> {
  const result = await this.api.post(`${API_HOST}/api/v1/account/create`, {
    email, password,
  }).catch((error) => {
    console.error({error})
    throw error?.response?.data || error;
  });
  const user = result.data.result;
  console.log('registerWithCredentials: the result is here:', { user });
  return user;
}

async updateProfile(idToken: string, fields: UpdateProfileParams) {
  const result = await this.api.post(`${API_HOST}/api/v1/account/update`, {
    displayName: fields.displayName,
  }, {
    headers: {
      [env.getAuthTokenHeaderKey()]: idToken,
    },
  }).catch((error) => {
    console.error({error})
    throw error?.response?.data ?? error;
  });
  const user = result.data.result;
  console.log('updateProfile: the result is here:', { user });
  return user;
}

async getProfile(idToken: string) {
  const result = await this.api.get(`${API_HOST}/api/v1/account`, {
    headers: {
      [env.getAuthTokenHeaderKey()]: idToken,
    },
  }).catch((error) => {
    console.error({error})
    throw error?.response?.data ?? error;
  });
  const user = result.data.result;
  console.log('getProfile: the result is here:', { user });
  return user;
}

async getAllCreations(idToken: string): Promise<ProductDatum[]> {
  const result = await this.api.get(`${API_HOST}/api/v1/products`, {
    headers: {
      [env.getAuthTokenHeaderKey()]: idToken,
    },
  }).catch((error) => {
    console.error({error})
    throw error?.response?.data ?? error;
  });
  const results = result.data.results as ProductDatum[];
  console.log('getAllCreations: the results are here:', { results });
  return results;
}


async getProductFeed(idToken: string|null): Promise<ProductFeed> {
  const request = await this.api.get(`${API_HOST}/api/v1/products/feed`, {
    headers: {
      [env.getAuthTokenHeaderKey()]: idToken ? idToken : undefined,
    },
  }).catch((error) => {
    console.error({error})
    throw error?.response?.data ?? error;
  });
  const result = request.data.result;
  console.log('getProductFeed: the results are here:', { result });
  return result;
}

async getProductById(
  idToken: string|null,
  productId: string,
): Promise<ProductDatum> {
  assert(typeCheck('String', productId), 'productId must be string');
  const request = await this.api.get(
    `${API_HOST}/api/v1/products/${productId}`, {
      headers: {
        [env.getAuthTokenHeaderKey()]: idToken ?? null,
      },
    }).catch((error) => {
      console.error({error})
      throw error?.response?.data ?? error;
    });
  const result = request.data.result;
  console.log('getProductById: the result is here:', { result });
  return result;
}

async createProduct(idToken: string, product: ProductDatum) {
  const request = await this.api.post(
    `${API_HOST}/api/v1/products/create`, product, {
      headers: {
        [env.getAuthTokenHeaderKey()]: idToken,
      },
    }).catch((error) => {
      console.error({error})
      throw error?.response?.data ?? error;
    });
    const result = request.data.result;
    console.log('createProduct: the result is here:', { result });
    return result;
  }

  async editProduct(
    idToken: string, product: ProductDatum,
  ) {
    const request = await this.api.post(
      `${API_HOST}/api/v1/products/edit`,
      product,
      {
        headers: {
          [env.getAuthTokenHeaderKey()]: idToken,
        },
      }).catch((error) => {
        console.error({error})
        throw error?.response?.data ?? error;
      });
    const result = request.data.result;
    console.log('editProduct: the result is here:', { result });
    return result;
  }

  async getUploadsForToken(idToken: string) {
    const result = await this.api.get(`${API_HOST}/api/v1/uploads`, {
      headers: {
        [env.getAuthTokenHeaderKey()]: idToken,
      },
    }).catch((error) => {
      console.error({error})
      throw error?.response?.data ?? error;
    });
    const results = result.data.results as FileUploadItem[];
    console.log('getUploads: the results are here:', { results });
    return results;
  }

  async getVideoInfo(videoId: string) {
    const request = await this.api.get(`${API_HOST}/api/v1/videos/${videoId}`, {
    }).catch((error) => {
      console.error({error})
      throw error?.response?.data ?? error;
    });
    const results = request.data.result;
    console.log('getVideoInfo: the result is here:', { results });
    return results;
  }

}
export default ApiService;