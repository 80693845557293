import { Box, Divider, IconButton, InputBase, Stack, TextField, Typography, useTheme } from '@mui/material';
import { AnyObject } from '../../types';
import { useRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';

type EditableLabelProps = {
  value: string,
  onChange?: (newValue: string) => void,
};


export default function EditableLabel({
  value = '',
  onChange = () => {},
}: EditableLabelProps) {
  const inputRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  if (isEditing) {
    return (
      <Box sx={{
        display: 'inline-block', position: 'relative'
      }}>
        <TextField
          inputRef={inputRef}
          fullWidth
          size={'small'}
          value={value}
          onChange={(e) => {
        const newValue = e.target.value;
        onChange(newValue);
      }}
      onBlur={() => {
        setIsEditing(false);
          }} />
        <IconButton sx={{
          position: 'absolute',
          right: 0,
        }}
          onClick={(e) => {
            setIsEditing(false);
        }}>
          <SaveIcon/>
          </IconButton>
      </Box>
    );
  }
  return (
    <Typography
      noWrap
      sx={{
        minWidth: '100px',
        minHeight: '28px',
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
       }}
      onClick={() => {
        setIsEditing(true);
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.selectionStart = 0;
            inputRef.current.selectionEnd = value.length;
          }
        });
      }}>
      {value}
    </Typography>
  );
}