
import React, { FormEventHandler, useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements
} from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { Alert, Button, Grid, Paper } from '@mui/material';
import Environment from '../../services/environment';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { userSlice } from '../../state/slices/user';
import { useAppSelector } from '../../state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Paths from '@web-client/paths';

const analytics = getAnalytics();

const CHECKOUT_COMPLETED_PATH = Paths.PRODUCT_CHECKOUT_COMPLETE;

const stripePromise = loadStripe(Environment.getInstance().getStripeApiKey());

export function CheckoutForm() {
  const host = Environment.getInstance().getCurrentHost();
  const completedUrl = `${host}${CHECKOUT_COMPLETED_PATH}`

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string|null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) { return };
      switch (paymentIntent.status) {
        case 'succeeded':
        setMessage('Payment succeeded!');
        break;
        case 'processing':
        setMessage('Your payment is processing.');
        break;
        case 'requires_payment_method':
        setMessage('Your payment was not successful, please try again.');
        break;
        default:
        setMessage('Something went wrong.');
        break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: completedUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? '');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'auto'
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
    <PaymentElement id='payment-element' options={paymentElementOptions} />

    <LoadingButton loading={isLoading}
    sx={{
      width: '100%', marginTop: 3, marginBottom: 2
    }}
    variant='contained'
    disabled={isLoading || !stripe || !elements}
    onClick={handleSubmit}>
    Pay Now
    </LoadingButton>
    {message && <Alert severity={'info'}>{message}</Alert>}
    </form>
  );
}


export default function Checkout() {
  const {
    productId = null,
    userId = null,
    action = null,
  } = useParams();

  const idToken = useAppSelector(userSlice.selectors.selectIdToken);
  const [clientSecret, setClientSecret] = useState('');
  useEffect(() => {
    logEvent(analytics, 'begin_checkout');

    // Create PaymentIntent as soon as the page loads
    const env = Environment.getInstance();
    const apiHost = env.getApiHost();
    const tokenHeaderKey = env.getAuthTokenHeaderKey();
    fetch(`${apiHost}/api/v1/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [tokenHeaderKey]: idToken ?? '',
      },
      body: JSON.stringify({
        userId,
        productId,
        action,
      }),
    })
    .then((res) => res.json())
    .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance: Appearance = {
    theme: 'night',
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <Grid container spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2 }}>
      <Grid item xs={12} md={5}>
        <Paper>PRODUCT: {productId}</Paper>
        <Paper>UserID: {userId}</Paper>
        <Paper>Action: {action}</Paper>

    <Paper elevation={3} sx={{ p: 3}}>
    {clientSecret && (
      <Elements options={options} stripe={stripePromise}>
      <CheckoutForm />
      </Elements>
    )}
      </Paper>
      </Grid>
    </Grid>
  );
}