import { Box, Button, Divider, IconButton, Stack, styled, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ThemedPaper from '../core/ThemedPaper';
import { ReactNode } from 'react';
import { ProductContent, ProductUnitTypes } from '../../services/ApiService';

import ArticleItemEditor from './ArticleItemEditor';
import VideoItemEditor from './VideoItemEditor';
import {v4 as uuidv4} from 'uuid';

type ProductItemEditorProps = {
  value?: ProductContent | null | undefined,
  onChange?: (newValue: ProductContent) => void,
  onDoneClick?: () => void,
};

function createProductContent() : ProductContent {
  return {
    id: uuidv4(),
    title: '',
    type: null,
    value: '',
  };
}
interface ProductTypeProps {
  children?: ReactNode | null | undefined,
  small?: boolean,
  isActive?: boolean,
}

function ProductType({
  children,
  small = false,
  isActive = false,
}: ProductTypeProps) {
  const theme = useTheme();

  const smallSize = 100;
  const largeSize = 250;

  return (
    <ThemedPaper
    elevation={5}
    sx={{
      width: !small ? largeSize : smallSize,
      height: !small ? largeSize : smallSize,
      borderRadius: 3,
      margin: 3,
      padding: theme.spacing(1),
      textAlign: 'center',
      backgroundColor: isActive ? theme.palette.primary.main : 'inherit',
    }}>
    {children}
    </ThemedPaper>
  );
}

function setProductType(itemState: ProductContent, type: ProductUnitTypes) {
  const clonedState = structuredClone(itemState);
  clonedState.type = type;
  clonedState.value = '';
  return clonedState;
}

function setTitle(itemState: ProductContent, title: string) {
  const clonedState = structuredClone(itemState);
  clonedState.title = title;
  return clonedState;
}

function setValue(itemState: ProductContent, value: string) {
  const clonedState = structuredClone(itemState);
  clonedState.value = value;
  return clonedState;
}


const TypeToEditorMapping = {
  [ProductUnitTypes.VIDEO]: VideoItemEditor,
  [ProductUnitTypes.ARTICLE]: ArticleItemEditor,
  'default': () => (<>Please choose a type.</>),
};

export default function ProductItemEditor({
  value,
  onChange = () => {},
  onDoneClick = () => {},
}: ProductItemEditorProps) {

  const Editor = TypeToEditorMapping[value?.type ?? 'default'];

  return (
    <>
    {value &&
      (<>
        <Button variant='outlined'
        sx={{ m: 2 }}
        onClick={() => {
          onDoneClick();
        }}>
        Done
        </Button>
        <Divider />
        </>
      )}

      <Box sx={{ m: 3 }}>
        <TextField
          variant='outlined'
          value={value?.title}
          label='Title'
          onChange={(e) => {
            const newTitle = e.target.value;
            const valueOrNew = value ?? createProductContent();
            onChange(setTitle(valueOrNew, newTitle));
          }}
        />
      </Box>
      <Box sx={{ m: 3 }}>
      <Typography>
      Choose a type.
      </Typography>
      </Box>
      <Stack
      justifyContent='center'
      alignItems='center'
      direction='row'
      spacing={5}
      sx={{
        m: 5,
      }}
      >
      <ProductType
      isActive={value?.type === ProductUnitTypes.VIDEO}
      small={value?.type != null}>
      <Tooltip title='Video'>
      <IconButton
      sx={{ borderRadius: 0 }}
      onClick={() => {
        const valueOrNew = value ?? createProductContent();
        onChange(setProductType(valueOrNew, ProductUnitTypes.VIDEO));
      }}>
      <SmartDisplayIcon sx={{
        height: '100%', width: '100%',
        cursor: 'pointer',
      }} />
      </IconButton>
      </Tooltip>

      </ProductType>
      <ProductType
      isActive={value?.type === ProductUnitTypes.ARTICLE}
      small={value?.type != null}>
      <Tooltip title='Article'>
      <IconButton sx={{ borderRadius: 0 }}
      onClick={() => {
        const valueOrNew = value ?? createProductContent();
        onChange(setProductType(valueOrNew, ProductUnitTypes.ARTICLE));
      }}>
      <NewspaperIcon sx={{
        height: '100%', width: '100%',
        cursor: 'pointer',
      }} />
      </IconButton>
      </Tooltip>
      </ProductType>
      </Stack>
      <Editor value={value?.value ?? ''}  onChange={(newValue) => {
        onChange(setValue(value ?? createProductContent(), newValue));
      }} />
      {/* <pre style={{ marginTop: 50, padding: 30 }}>
      {JSON.stringify(value ?? {}, null, 4)}
      </pre> */}
      </>
    );
  }