import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { refreshIdToken, selectIdToken } from '../state/slices/user';
import { useAppDispatch, useAppSelector } from './../state/store';
import { useEffect, useRef } from 'react';
import ApiService from '../services/ApiService';
import { LinearProgress } from '@mui/material';
import Paths from '../paths';

const apiService = ApiService.getInstance();

const TOKEN_RENEWAL_MINUTES = 10;


function renewToken(dispatch) {
  dispatch(refreshIdToken());
}


const ProtectedRoute = function ({ children }) {

  const timerRef = useRef(null);

  const idToken = useAppSelector(selectIdToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const authFailureHandler = useCallback(function renewTokenOnFailure(error) {
    console.warn('Attempting to refresh token from ProtectedRoute', error);
    setIsRefreshingToken(true);
    dispatch(refreshIdToken()).then(() => {
      setIsRefreshingToken(false);
    });
  }, [dispatch]);

  useEffect(() => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      console.log('Updating token');
      renewToken(dispatch);
    }, 1000 * 60 * TOKEN_RENEWAL_MINUTES);

    apiService.onFailure(authFailureHandler);
    return () => {
      clearInterval(timerRef.current);
      // console.warn('REMOVING FAILURE HANDLER');
      apiService.removeFailureListener(authFailureHandler);
    }
  }, [authFailureHandler]);

  useEffect(() => {
    if (!idToken) {
      navigate(Paths.LOGIN);
    }
  });

  if (isRefreshingToken) {
    return (
      <>
        Credentials expired.... renewing credentials
        <LinearProgress />
      </>
    );
  }

  return (
    <>
      {children}
    </>
  );
};


export default ProtectedRoute;