import { onAuthStateChanged } from "firebase/auth";
import { ReactNode, useEffect } from "react";
import { userSlice } from "../state/slices/user";
import { useAppDispatch } from "../state/store";
import { auth, FirebaseUserSerialized } from "../firebase";
import { getAnalytics, setUserId } from "firebase/analytics";

const analytics = getAnalytics();
interface ContentWrapperProps {
  children?: ReactNode
}

export const ContentWrapper = function ({ children }: ContentWrapperProps) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    onAuthStateChanged(auth, async (userInfo) => {
      console.log('Auth state has changed...', { userInfo });
      const currentUser = auth.currentUser;
      console.log({ currentUser})
      let idToken = null;
      if (userInfo) {
        idToken = await userInfo.getIdToken();
        setUserId(analytics, userInfo.email);
      }
      dispatch(userSlice.actions.setIdToken(idToken));
      const serializedUser = userInfo?.toJSON() as FirebaseUserSerialized;
      dispatch(userSlice.actions.setUser(serializedUser ?? null));
    });
  });
  return (
    <>
    {children}
    </>
  );
}