import { Box, Button, Card, CircularProgress, debounce, ImageList, ImageListItem, Theme, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllCreations, selectCreations, selectIsGettingCreations } from "../../state/slices/creations";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { userSlice } from "../../state/slices/user";
import ThemedPaper from "../core/ThemedPaper";
import Paths from "../../paths";

const DUMMY_IMAGE = 'https://picsum.photos/seed/300/300';

const DEBOUNCE_WAIT_MS = 1;

const loadCreations = debounce((idToken, dispatch) => {
  return dispatch(getAllCreations(idToken));
}, DEBOUNCE_WAIT_MS);


export function MyCreationsList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const idToken = useAppSelector(userSlice.selectors.selectIdToken);

  const creations = useAppSelector(selectCreations);
  const isGettingCreations = useAppSelector(selectIsGettingCreations);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));


  useEffect(() => {
    if (idToken) {
      loadCreations(idToken, dispatch);
    }
  }, [idToken, dispatch]);

  if (isGettingCreations) {
    return <CircularProgress />;
  }


  return (
      <ImageList sx={{ width: '100%', height: '100%' }} cols={isSmallScreen ? 2 : 5} >
      {creations.map((item, index) => {
        const editPath = Paths.PRODUCT_EDIT
          .replace(':productId', String(item.id));
        return (
        <ImageListItem key={index}>
        <img
        srcSet={`${item.imageUrl ?? DUMMY_IMAGE}`}
        src={`${item.imageUrl ?? DUMMY_IMAGE}`}
        alt={item.title}
        loading="lazy"
        />
        <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
        <ThemedPaper sx={{ p: 2, m: 3}}>
        {item.title}
        </ThemedPaper>
        <Button
        sx={{ marginRight: 2, marginTop: 2}}
        variant="contained"
        onClick={() => navigate(editPath)}>
        Details
        </Button>
        </Box>

        </ImageListItem>
      )})}
      </ImageList>
  );
}

export default MyCreationsList;