import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiService, { FileUploadItem, UpdateProfileParams } from '../../services/ApiService';
import assert from 'assert';
import {typeCheck} from 'type-check';
import { auth, FirebaseUserSerialized } from '../../firebase';
import { getAuth } from '@firebase/auth';

const USER_TOKEN_KEY = 'idToken';
export interface UserSliceState {
  idToken: string | null,
  isLoggingIn: boolean,
  isRegistering: boolean,
  isGettingProfile: boolean,
  isUpdatingProfile: boolean,
  isGettingUploads: boolean,
  errors: string[],
  user: FirebaseUserSerialized | null,
  uploads: FileUploadItem[],
}

const initialState: UserSliceState = {
  idToken: window.localStorage.getItem(USER_TOKEN_KEY),
  isLoggingIn: false,
  isRegistering: false,
  isGettingProfile: false,
  isUpdatingProfile: false,
  isGettingUploads: false,
  errors: [],
  user: null,
  uploads: [],
};

type LoginCredentials = {
  email: string, password: string
};

function persistIdToken(idToken: string) {
  assert(typeCheck('String|null|undefined', idToken));
  window.localStorage.setItem(USER_TOKEN_KEY, idToken);
}

function clearIdToken() {
  window.localStorage.removeItem(USER_TOKEN_KEY);
}

export const refreshIdToken = createAsyncThunk(
  'users/refreshIdToken', async () => {
    console.log('refresh token dispatched, trying to get new...');
    return await getAuth().currentUser?.getIdToken(true);
});

export const registerWithCredentials = createAsyncThunk(
  'users/registerWithCredentials', async (creds: LoginCredentials) => {
    const { email, password } = creds;
    const result = await ApiService.getInstance()
      .registerWithCredentials(email, password);
    return result;
  });

export const loginWithCredentials = createAsyncThunk(
  'users/loginWithCredentials', async (creds: LoginCredentials) => {
    const { email, password } = creds;
    const result = await ApiService.getInstance()
      .loginWithCredentials(email, password);
    return result;
});

export const getProfileForToken = createAsyncThunk(
  'users/getProfileForToken', async (idToken: string) => {
    const result = await ApiService.getInstance()
      .getProfile(idToken);
    return result;
});

export const getUploads = createAsyncThunk(
  'users/getUploads', async (idToken: string) => {
    const result = await ApiService.getInstance()
      .getUploadsForToken(idToken);
    return result;
});

export const updateProfileForToken = createAsyncThunk(
  'users/updateProfileForToken', async (params: {
    idToken: string,
    fields: UpdateProfileParams,
  }) => {
    const {idToken, fields} = params;
    const result = await ApiService.getInstance()
      .updateProfile(idToken, fields);
    return result;
});

export const logout = createAsyncThunk('users/logout', async () => {
  await auth.signOut();
  clearIdToken();
  return
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: (create) => ({
    setIdToken: create.reducer((state, action: PayloadAction<string|null>) => {
      state.idToken = action.payload;
      persistIdToken(action.payload ?? '');
    }),
    clearErrors: create.reducer((state) => {
      state.errors.length = 0;
    }),
    setUser: create.reducer((
      state, action: PayloadAction<FirebaseUserSerialized | null>
    ) => {
      state.user = action.payload as FirebaseUserSerialized;
    }),
  }),
  extraReducers: (builder) => {

    // ------------ Refresh Token ---------------
    builder.addCase(refreshIdToken.pending, (state, action) => {
    }).addCase(refreshIdToken.fulfilled, (state, action) => {
      const newToken = action.payload;
      if (newToken != null) {
        state.idToken = newToken;
      }
      persistIdToken(newToken ?? '');
    }).addCase(refreshIdToken.rejected, (state, action) => {
      // TODO(benedictchen): Perhaps show a global error?
    });

    // ------------ Login ---------------
    builder.addCase(loginWithCredentials.pending, (state, action) => {
      state.isLoggingIn = true;
      state.errors.length = 0;
    }).addCase(loginWithCredentials.fulfilled, (state, action) => {
      const user = action.payload;
      state.isLoggingIn = false;
      const idToken: string = action.payload.idToken;
      if (!typeCheck('String', idToken)) {
        throw new TypeError(idToken);
      }
      state.idToken = idToken;
      state.user = user
      persistIdToken(idToken ?? '');
    }).addCase(loginWithCredentials.rejected, (state, action) => {
      state.isLoggingIn = false;
      state.errors.push(action.error?.message ?? 'Unknown Error');
    });

    // ------------ Register ---------------
     builder.addCase(registerWithCredentials.pending, (state, action) => {
       state.isRegistering = true;
       state.errors.length = 0;
    }).addCase(registerWithCredentials.fulfilled, (state, action) => {
      state.isRegistering = false;
      const idToken: string = action.payload.idToken;
      if (!typeCheck('String', idToken)) {
        throw new TypeError(idToken);
      }
      state.idToken = idToken;
      persistIdToken(idToken ?? '');
    }).addCase(registerWithCredentials.rejected, (state, action) => {
      state.isRegistering = false;
      console.log('fail', action.payload);
      // TODO(benedictchen): We need to use error reporting system here.
      console.error({
        error: action.error
      });
      state.errors.push(action.error?.message ?? 'Unknown Error');
    });
    // ------------ Logout ---------------
    builder.addCase(logout.pending, (state, action) => {
      state.isLoggingIn = false;
      state.isRegistering = false;
      state.errors.length = 0;
    }).addCase(logout.fulfilled, (state, action) => {
      state.idToken = null;
    }).addCase(logout.rejected, (state, action) => {
      // Do nothing if logout fails?
    });

    // ------------ Get Profile ---------------
    builder.addCase(getProfileForToken.pending, (state, action) => {
      state.isGettingProfile = true;
    }).addCase(getProfileForToken.fulfilled, (state, action) => {
      state.isGettingProfile = false;
    }).addCase(getProfileForToken.rejected, (state, action) => {
      state.isGettingProfile = false;
    });

    // ------------ Update Profile ---------------
    builder.addCase(updateProfileForToken.pending, (state, action) => {
      state.isUpdatingProfile = true;
    }).addCase(updateProfileForToken.fulfilled, (state, action) => {
      state.isUpdatingProfile = false;
    }).addCase(updateProfileForToken.rejected, (state, action) => {
      state.isUpdatingProfile = false;
    });

    // ------------ Get Uploads ---------------
    builder.addCase(getUploads.pending, (state, action) => {
      state.isGettingUploads = true;
      state.uploads = [];
    }).addCase(getUploads.fulfilled, (state, action) => {
      state.isGettingUploads = false;
      state.uploads = action.payload;
    }).addCase(getUploads.rejected, (state, action) => {
      state.isGettingUploads = false;
      state.uploads = [];
    });

  },
  selectors: {
    selectIdToken: (state) => state.idToken,
    isLoggingIn: (state) => state.isLoggingIn,
    isRegistering: (state) => state.isRegistering,
    isGettingProfile: (state) => state.isGettingProfile,
    isUpdatingProfile: (state) => state.isUpdatingProfile,
    selectErrors: (state) => state.errors,
    selectUser: (state) => state.user,
    selectUploads: (state) => state.uploads,
    selectIsGettingUploads: (state) => state.isGettingUploads,
  }
});

// Actions
export const {
  setIdToken,
  clearErrors,
} = userSlice.actions;

export const {
  selectUploads,
  selectIdToken,
  selectUser,
  selectIsGettingUploads,
} = userSlice.selectors;

export default userSlice.reducer;